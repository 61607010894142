/* eslint no-console:0 */

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import 'materialize-css/dist/js/materialize.js';
import Vue from 'vue/dist/vue.esm';

import VueResource from 'vue-resource';
Vue.use(VueResource);

import VueCanCan from 'vue-cancan';

import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo, { offset: -100 });

import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter);

import Moment from 'vue-moment';
Vue.use(Moment);

import { i18n } from '../i18n-js/index.js';

import Event from'../views/events/event.vue';

document.addEventListener('turbolinks:load', () => {
  // #TODO: fix error, dont render on Vue, need window.onload y JS request

  // 'Conent-Type': 'text/javascript'
  // 'Accept': 'text/javascript'
  // 'dataType': 'script'


  // window.onload = function () {
  Vue.http.headers.common['X-CSRF-Token'] = document.querySelector('meta[name=csrf-token]')
                                                    .getAttribute('content');
  // }


  const element = document.getElementById('view_events');
  let props = null;

  if (element != null) props = JSON.parse(element.getAttribute('data'));

  if (element != null && props != null) {
    props.events = JSON.parse(props.events);
    props.init_aircrafts = JSON.parse(props.init_aircrafts);
    props.init_pilots = JSON.parse(props.init_pilots);
    props.init_new_event = JSON.parse(props.init_new_event);
    props.current_account = JSON.parse(props.current_account);
    Vue.use(VueCanCan, { rules: JSON.parse(props.abilities) });

    const app = new Vue({
      el: '#view_events',
      components: {
        event: Event,
      },
      i18n,
      render: h => h(Event, { props }),
    });
  }
});
