
<template>
  <div id="app">
    <div :class="row">
      <div :class="['col', size]">
        <div class="card">
          <div class="card-action card-action_tawa" @click="displayShowContent">
            <span class="card-title white-text">{{ state_title }}</span>
          </div>
          <div class="card-content card-content-events" >
            <div v-show="show_form_content">
              <slot name="table"></slot>
            </div>
            <div v-show="show_form2_content">
              <slot name="table2"></slot>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { bus } from '../../packs/event_bus';
  export default {
    data() {
      return {
        state_title: this.title.title,
        show_form_content: true,
        show_form2_content: true, //for now but it shoud be false
        size: 's12',
        row: 'row',
      }
    },
    props: ['title', 'setSize', 'thisRow'],
    created() {
      if (!!this.setSize) this.size = this.setSize
      if (this.thisRow === "") this.row = this.thisRow
    },
    methods: {
      displayShowContent: function() {
        this.show_form_content = !this.show_form_content;
        var ce = this.$children[0].current_event;
        if (ce && !this.show_form_content) {//true
          this.state_title = `${ce.aircraft.registration} - ${ce.from_flight_date_date}, ${ce.from_flight_date_time} / ${ce.to_flight_date_date}, ${ce.to_flight_date_time}`;
          this.show_form2_content = true;
        } else if (ce && this.show_form_content) {
          this.state_title = 'Edit Event';
          this.show_form2_content = true;
        } else if (this.state_title == 'Edit Event'){
          this.state_title = 'Create New Event';
          this.show_form_content = true;
          this.show_form2_content = false;
        }
      },
    }
  }
</script>
