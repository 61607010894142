<template>
  <div id="time_line">
    <div class="new_object">
      <a class="waves-effect waves-light btn right new_object__btn"
         @click="change_display_type('programed')">
         <i class="material-icons new_object__btn__i right medium white-text">assignment</i>
      </a>
    </div>
    <div class="col s12 search_fields no-border">

      <div class="input-field col s6 m5 l5">
        <input id="search_from_date" type="text" v-model="search.search_from_date" class="validate date search_from_date">
        <label for="search_from_date">{{ $t(`${cas}.event.from_date`) }}</label>
        <span class="helper-text error_text" :data-error="error_text.search_from_date"/>
      </div>

      <div class="input-field col s6 m5 l5">
        <input id="search_to_date" type="text" v-model="search.search_to_date" class="validate date search_to_date">
        <label for="search_to_date">{{ $t(`${cas}.event.to_date_optional`) }}</label>
        <span class="helper-text error_text" :data-error="error_text.search_to_date"/>
      </div>

      <a id="timeline_search_btn" class="button_search waves-effect waves-light btn col s12 m2 l2"
      @click="locate_time(search.search_from_date, search.search_to_date)">
        {{ $t(`${cas}.event.time_line`) }}
      </a>

      <div class="input-field col s12">
        <select id="users" multiple v-model.lazy="selected_groups" >
          <optgroup :label="$t(`${cas}.event.aircraft`)">
            <option v-for="aircraft in aircrafts" :value="'aircraft-' + aircraft.id"
            >{{ aircraft.registration }} - {{ aircraft.aircraft_type }}</option>
          </optgroup>
          <optgroup :label="$t(`${cas}.aircraft_log.first_officer.vue.label`)">
            <option v-for="pilot in pilots" :value="'user-' + pilot.id" v-if="pilot.level == 3"
            > {{ pilot.name }}</option>
          </optgroup>
          <optgroup :label="$t(`${cas}.aircraft_log.pilot_in_command.vue.label`)">
            <option v-for="pilot in pilots" :value="'user-' + pilot.id" v-if="pilot.level == 2 || pilot.level == 1"
            > {{ pilot.name }}</option>
          </optgroup>
        </select>
        <label>{{ $t(`${cas}.event.select_resources`) }}</label>
      </div>
    </div>

    <div id="calendar"></div>
  </div>
</template>

<script>
  import { DataSet, Timeline } from 'vis/index-timeline-graph2d';
  import { bus } from '../../packs/event_bus';
  import { applicationMixin } from '../../mixins/application_mixin'


 // lazy fix if you want to use jQuery in your inline scripts.

  export default {
    props: ['events', 'aircrafts', 'pilots', 'line_events', 'ca'],
    data() {
      return {
        cas: this.ca.account_type,
        timeline: null,
        search: {
          search_from_date: '',
          search_to_date: '',
        },
        error_text: {},
        picker: {
          fromDatePikerinstances: null,
          toDatePikerinstances: null,
        },
        groupList: [],
        type_display: null
      }
    },
    computed: {
      selected_groups: {
        get: function() {
          return []
        },
        set: function (ids) {
          this.updateGroup(ids)
        }
      }
    },
    created() {
      this.$parent.state_title = this.$t(`${this.cas}.event.calendar_event`);
      bus.$on('updateTimeLine',(events_pass) =>{
        this.updateTimeLine(events_pass)
      });
    },
    mounted: function() {
      var elems = document.querySelectorAll('select');
      var instances = M.FormSelect.init(elems, {});
      var vm = this
      vm.aircrafts.forEach(function(obj) {
        vm.groupList.push({'content': obj.registration, 'id': 'aircraft-' + obj.id, 'className': 'inner_row','style': 'border-bottom: none;'})
      });
      vm.pilots.forEach(function(obj) {
        // todo hacer esto dinamico
        if (!(obj.level == 3)){
          vm.groupList.push({'content': obj.name, 'id': 'user-' + obj.id, 'className': 'inner_row','style': 'border-bottom: none;'})
        }
      })
      var groups = new DataSet(vm.groupList);

      // create visualization
      var container = document.getElementById('calendar');
      var options = {
        // option groupOrder can be a property name or a sort function
        // the sort function must compare two groups and return a value
        //     > 0 when a > b
        //     < 0 when a < b
        //       0 when a == b
        groupOrder: function (a, b) {
          return a.value - b.value;
        },
        groupOrderSwap: function (a, b, groups) {
        	var v = a.value;
        	a.value = b.value;
        	b.value = v;
        },
        zoomMax: 1209600000,
        zoomMin: 1800000,
        margin: {
          item: {
            horizontal: -15,
          }
        },
        clickToUse: true,
        orientation: 'top',
        start: this.$moment(new Date()),
        end: this.$moment(new Date()).add(1,'days')
      };

      var instanceTimeline = new Timeline(container, new DataSet([]), groups, options);
      this.timeline = instanceTimeline;
      // bus.$emit('displayUpdatedLogs')

      instanceTimeline.on('select', function(event) {
        bus.$emit('displayEvent', event.items[0].match(/.*?-.*?-(.*)/)[1])
      })
      // materializecss DatePicker
      if (this.picker.fromDatePikerinstances === null) {
        var searchFromDatePiker = document.querySelectorAll('#search_from_date');
        var searchToDatePiker = document.querySelectorAll('#search_to_date');
        let vm = this

        this.picker.fromDatePikerinstances = M.Datepicker.init(searchFromDatePiker, {
          showClearBtn: true,
          autoClose: true,
          onClose: function() {
            vm.search.search_from_date = this.$el[0].value
            vm.picker.toDatePikerinstances.options.minDate = vm.time_generator(vm.search.search_from_date);
          }
        })[0];
        this.picker.toDatePikerinstances = M.Datepicker.init(searchToDatePiker, {
          showClearBtn: true,
          autoClose: true,
          onClose: function() {
            vm.search.search_to_date = this.$el[0].value
            vm.picker.fromDatePikerinstances.options.maxDate = vm.time_generator(vm.search.search_to_date);
          }
        })[0];
      }
      instanceTimeline.on('rangechanged', function (properties) {
        let current_times = this.timeline.getWindow()
        this.$http.get(`/en/events?utf8=✓&from=${new Date(current_times.start)}&to=${new Date(current_times.end)}`).then(response => {
          this.$parent.$parent.current_events = response.body.events;
          this.updateTimeLine(response.body.time_line_events);
          bus.$emit('cancelDisplayNoAjax');
          bus.$emit('notAcceptedNewLog', this.$parent.$parent.current_events);
          bus.$emit('AcceptedNewLog', this.$parent.$parent.current_events);
          bus.$emit('formAcceptedNewLog', this.$parent.$parent.current_events);
        });
      }.bind(this));
    },
    methods: {
      locate_time: function(from, to) {
        from = this.time_generator(from);
        to = this.time_generator(to);
        if (from === '' && to === '') {
          this.timeline.setWindow(this.$moment(new Date().setHours(7, 0, 0)), this.$moment(new Date().setHours(24, 0, 0)));
        } else if (from  && to === '') {
          var current_from = this.$moment(from);
          var to = this.$moment(from).add(1, 'days');
          this.timeline.setWindow(current_from, to);
        } else if (from  && to) {
          this.timeline.setWindow(this.$moment(from), this.$moment(to).add(1,'days'));
        }
      },
      updateTimeLine: function(new_events) {
        this.timeline.setItems(new_events);
      },
      change_display_type: function(type_display) {
        bus.$emit('selectedDisplayType', type_display);
      },
      hideAllGroups: function (){
        this.groupList.forEach(function(group){
          group.visible = false
        }.bind(this))
      },
      showAllGroups: function (){
        this.groupList.forEach(function(group){
          group.visible = true
        }.bind(this))
        this.timeline.setGroups(this.groupList)
      },
      updateGroup: function(groupIdsArray) {
        if (groupIdsArray === undefined || groupIdsArray.length == 0) {
          this.showAllGroups()
        } else {
          this.hideAllGroups();
          groupIdsArray.forEach(function(id){
             var current_group_index = this.groupList.findIndex((obj => obj.id == id));
             this.groupList[current_group_index].visible = true
          }.bind(this));
          this.timeline.setGroups(this.groupList)
        }
      }
    },
    mixins: [applicationMixin]
  }
</script>
