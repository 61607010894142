
<template>
  <div id="log">
    <div v-if="details_show">
      <form class="" >
        <div class="no-border col s12">
          <div class="input-field col s12 l4"
            v-if="is_it_aircraft()">
            <input id="flight_date" type="text" v-model="this_log.flight_date" class="validate date flight_date">
            <label for="flight_date">{{ $t(`${cas}.aircraft_log.flight_date`) }}</label>
            <span class="helper-text error_text" :data-error="error_text.flight_date"/>
          </div>
          <div class="input-field col s6 l4"
            v-if="is_it_aircraft()">
            <input id="from_city" type="text" v-model="this_log.from_city" class="validate time from_city">
            <label for="from_city">{{ $t(`${cas}.aircraft_log.from_city`) }}</label>
            <span class="helper-text error_text" :data-error="error_text.from_city"/>
          </div>
          <div class="input-field col s6 l4"
               v-if="is_it_aircraft()">
            <input id="to_city" type="text" v-model="this_log.to_city" class="validate time to_city">
            <label for="to_city">{{ $t(`${cas}.aircraft_log.to_city`) }}</label>
            <span class="helper-text error_text" :data-error="error_text.to_city"/>
          </div>
          <div class="col s12 l6 no-border">
            <div class="input-field col s6 l6 pilots pilot_in_command">
              <select v-model="selected_officer" id="user_two_one" >
                <optgroup :label="$t(`${cas}.aircraft_log.pilot_in_command.vue.dropdown`)">
                  <option value="null">{{ $t(`${cas}.share.none_selected`) }}</option>
                  <option v-for="pilot in current_pilot_list" :value="pilot.id" v-if="pilot.level == 2 || pilot.level == 1"> {{pilot.name}}</option>
                </optgroup>
              </select>
              <label>{{ $t(`${cas}.aircraft_log.pilot_in_command.vue.label`) }}</label>
            </div>
            <div class="input-field col s6 l6 pilots first_officer">
              <select v-model="selected_pilot" id="user_three" >
                <optgroup :label="$t(`${cas}.aircraft_log.first_officer.vue.dropdown`)">
                  <option value="null">{{ $t(`${cas}.share.none_selected`) }}</option>
                  <option v-for="pilot in current_pilot_list" :value="pilot.id"> {{pilot.name}}</option>
                </optgroup>
              </select>
              <label>{{ $t(`${cas}.aircraft_log.first_officer.vue.label`) }}</label>
            </div>
            <div class="input-field col s6 l6"
              v-if="is_it_aircraft() && is_it_school()"
              v-show="is_it_instructor()">
                <input id="ins_time" type="number" min='0' step="0.1" v-model="this_log.ins_time" class="validate time ins_time">
                <label for="ins_time">{{ $t(`${cas}.aircraft_log.ins_time`) }}</label>
                <span class="helper-text error_text" :data-error="error_text.ins_time"/>
            </div>
            <div class="input-field col s6 l6">
              <input id="invoice" type="text" v-model="this_log.invoice" class="validate invoice">
              <label for="invoice">{{ $t(`${cas}.aircraft_log.invoice`) }}</label>
              <span class="helper-text error_text" :data-error="error_text.invoice"/>
            </div>
          </div>
          <div class="col s12 l6 no-border"
               v-if="is_it_aircraft()">
            <div class="col s12 no-border" v-if="!is_it_sim()">
              <div class="input-field col s6 l4">
                <input id="start_tach" type="number" min='0' step="0.1" v-model.lazy="this_log.start_tach" class="validate time start_tach">
                <label for="start_tach">{{ $t(`${cas}.aircraft_log.start_tach`) }}</label>
                <span class="helper-text error_text" :data-error="error_text.start_tach"/>
              </div>
              <div class="input-field col s6 l4">
                <input id="end_tach" type="number" min='0' step="0.1" v-model.lazy="this_log.end_tach" class="validate time end_tach">
                <label for="end_tach">{{ $t(`${cas}.aircraft_log.end_tach`) }}</label>
                <span class="helper-text error_text" :data-error="error_text.end_tach"/>
              </div>
              <div class="col s12 l4">
                <div class="col s12 div_log_times no-border">
                  <span>{{ $t(`${cas}.aircraft_log.tach_time`) }}</span>
                  <br>
                  <span id="span_log_sum" type="number">
                    {{(this_log.end_tach - this_log.start_tach).toFixed(1)}}
                  </span>
                </div>
              </div>
            </div>
            <div class="col s12 no-border">
              <div class="input-field col s6 l4">
                <input id="start_hobbs" type="number" min='0' step="0.1" v-model.lazy="this_log.start_hobbs" class="validate time start_hobbs">
                <label for="start_hobbs">{{ $t(`${cas}.aircraft_log.start_hobbs`) }}</label>
                <span class="helper-text error_text" :data-error="error_text.start_hobbs"/>
              </div>
              <div class="input-field col s6 l4">
                <input id="end_hobbs" type="number" min='0' step="0.1" v-model.lazy="this_log.end_hobbs" class="validate time end_hobbs">
                <label for="end_hobbs">{{ $t(`${cas}.aircraft_log.end_hobbs`) }}</label>
                <span class="helper-text error_text" :data-error="error_text.end_hobbs"/>
              </div>
              <div class="col s12 l4">
                <div class="col s12 div_log_times no-border">
                  <span>{{ $t(`${cas}.aircraft_log.hobbs_time`) }}</span>
                  <br>
                  <span id="span_log_sum" type="number">
                    {{(this_log.end_hobbs - this_log.start_hobbs).toFixed(1)}}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col s12 no-border">
            <div class="input-field col s6 l3"
                 v-if="!is_it_aircraft() && is_it_school()">
              <input id="ins_time" type="number" min='0' step="0.1" v-model="this_log.ins_time" class="validate time ins_time">
              <label for="ins_time">{{ $t(`${cas}.aircraft_log.ins_time`) }}</label>
              <span class="helper-text error_text" :data-error="error_text.ins_time"/>
            </div>
            <div class="input-field col s12 l9">
              <textarea id="log_notes" type="text" v-model="this_log.notes" class="validate notes materialize-textarea"></textarea>
              <label for="log_notes">{{ $t(`${cas}.aircraft_log.notes`) }}</label>
              <span class="helper-text error_text" :data-error="error_text.notes"/>
            </div>
          </div>
        </div>
      </form>
      <div class="acceptance_hover">
        <div v-if="!(this_log.id)">
          <a id="btn_cancel_log" class="waves-effect waves-light btn right separated_button z-depth-0"
          @click="cancelNewLog"
          v-scroll-to="'#logs'">
            {{ $t(`${cas}.aircraft_log.form_cancel_btn`) }}
          </a>
          <a id="btn_create_log" class="waves-effect waves-light btn right separated_button z-depth-0"
          @click="createNewLog"
          :disabled="incall"
          v-can.create.AircraftLog>
            {{ $t(`${cas}.aircraft_log.form_create_btn`) }}
          </a>
        </div>
        <div v-else>
          <a id="btn_update_log" class="waves-effect waves-light btn right separated_button z-depth-0"
            @click="updateLog"
            :disabled="incall"
            v-if="event_is_accepted()"
            v-can.update.AircraftLog>
            {{ $t(`${cas}.aircraft_log.form_update_btn`) }}
          </a>
          <a id="btn_destroy_log" class="waves-effect waves-light btn right separated_button z-depth-0"
            @click="destroy_log"
            :disabled="incall"
            v-scroll-to="'#logs'"
            v-if="event_is_accepted()"
            v-can.destroy.AircraftLog>
            {{ $t(`${cas}.aircraft_log.form_destroy_btn`) }}
          </a>
          <a id="add_squawk" class="waves-effect waves-light btn right separated_button z-depth-0 modal-trigger"
             data-target="modal"
             data-remote="true"
             :href="'/en/squawks/new?[squawk]aircraft_id='+this_log.aircraft.id+'&[squawk]aircraft_log_id='+this_log.id">
            {{ $t('aircraft_log.add_squawk') }}
          </a>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { bus } from '../../packs/event_bus';
  import Log from './log';
  import { applicationMixin } from '../../mixins/application_mixin';
  import VueScrollTo from 'vue-scrollto';
  import 'select2'

  export default {
    props: ['log', 'pilots', 'ca'],
    components: {},
    data() {
      return {
        details_show: false,
        this_log: this.log,
        current_pilot_list: this.pilots,
        incall: false,
        error_text: {},
        picker: {
          flightDatePikerinstances: null,
        },
        cas: this.ca.account_type
      }
    },
    computed: {
      selected_pilot: {
        get: function () {
          let this_event = this.$parent.$parent.$children[0];
          if (this.this_log.pilot_in_command_id != null && this_event.current_event.users != null) {
            this.current_pilot_list = this_event.current_event.users;
            return this.this_log.pilot_in_command_id
          } else {
            return null
          }
        },
        set: function (id) {
          this.this_log.pilot_in_command_id = id
        }
      },
      selected_officer: {
        get: function () {
          let this_event = this.$parent.$parent.$children[0];
          if (this.this_log.first_officer_id != null && this_event.current_event.users != null) {
            this.current_pilot_list = this_event.current_event.users;
            return this.this_log.first_officer_id
          } else {
            return null
          }
        },
        set: function (id) {
          this.this_log.first_officer_id = id
        }
      }
    },
    created() {
      // this.this_log = this.log
    },
    mounted: function() {
      if (this.this_log.flight_date) {
        this.this_log.flight_date = this.dateTimeHandlerMix(this.this_log.flight_date, 'date');
      }
      if (this.this_log.id === null) {
        var title_el = document.getElementById('log-id-null');
        title_el.className += " active";
        this.details_show = true;
        VueScrollTo.scrollTo('#log-id-null');
      }
      this.current_pilot_list = this.$parent.$parent.$children[0].current_event.users
    },
    updated: function() {
      var vm = this
      var $pilot_in_command = null
      var $first_officer = null

      if (this.cas === 'school') {
        $pilot_in_command = $(vm.$el).find('.first_officer select')
        $first_officer = $(vm.$el).find('.pilot_in_command select')
      } else {
        $pilot_in_command = $(vm.$el).find('.pilot_in_command select')
        $first_officer = $(vm.$el).find('.first_officer select')
      }

      $pilot_in_command.select2().val(vm.this_log.pilot_in_command_id).trigger('change').on('change', function () {
        if (vm.this_log.pilot_in_command_id !== Number($(this).val())) {
          var num = $(this).val()
          if (num == '0') num = null
          vm.this_log.pilot_in_command_id = num
        }
      })

      $first_officer.select2().val(vm.this_log.first_officer_id).trigger('change').on('change', function () {
        if (vm.this_log.first_officer_id !== Number($(this).val())) {
          var num = $(this).val()
          if (num == '0') num = null
          vm.this_log.first_officer_id = num
        }
      })

      if (!(this.log.id == this.this_log.id)){
        this.this_log = this.log
        this.picker.flightDatePikerinstances.destroy();
        this.picker.flightDatePikerinstances = null;
      }
      M.updateTextFields();
      if (this.picker.flightDatePikerinstances) {
        this.picker.flightDatePikerinstances.destroy();
        this.picker.flightDatePikerinstances = null;
      }
      if (this.picker.flightDatePikerinstances === null ) {
        var log_el = this.$el
        var flightDatePiker = log_el.querySelectorAll(`#flight_date`);
        let vm = this

        if (this.this_log.flight_date) {
          this.this_log.flight_date = this.dateTimeHandlerMix(this.this_log.flight_date, 'date');
        }

        this.picker.flightDatePikerinstances = M.Datepicker.init(flightDatePiker, {
          autoClose: true,
          defaultDate: new Date(this.call_current_event().from_flight_date),
          minDate: new Date(this.call_current_event().from_flight_date),
          maxDate: new Date(this.call_current_event().to_flight_date),
          onClose: function() {
            vm.this_log.flight_date = this.$el[0].value
          }
        })[0];
      }
      var end_tach_local = parseFloat(this.this_log.end_tach)
      var start_tach_local = parseFloat(this.this_log.start_tach)
      if (end_tach_local < start_tach_local) {
        if (end_tach_local != start_tach_local) this.this_log.end_tach = this.this_log.start_tach
      }
      var end_hobbs_local = parseFloat(this.this_log.end_hobbs)
      var start_hobbs_local = parseFloat(this.this_log.start_hobbs)
      if (end_hobbs_local < start_hobbs_local) {
        if (end_hobbs_local != start_hobbs_local) this.this_log.end_hobbs = this.this_log.start_hobbs
      }
    },
    methods: {
      is_it_school: function () {
        return this.cas == 'school'
      },
      call_current_event: function() {
        return this.$parent.$parent.$children[0].current_event
      },
      is_it_aircraft: function() {
        return !this.call_current_event().aircraft.non_aircraft
      },
      is_it_instructor: function() {
        return this.this_log.first_officer_id != null && this.this_log.first_officer_id != 'null'
      },
      is_it_sim: function() {
        return this.call_current_event().aircraft.sim
      },
      cancelNewLog: function() {
        this.$parent.current_logs = this.$parent.current_logs.filter(l => !(l.id === null));
      },
      createNewLog: function() {
        // TODO: remove the fucking aircraft_id en do it from de model. cristhian
        if (this.incall) return
        this.incall = true
        var flight_date = this.validateTime();
        this.$http.post('/en/aircraft_logs', {
          aircraft_log: {
            flight_date: flight_date,
            start_tach: this.this_log.start_tach,
            end_tach: this.this_log.end_tach,
            start_hobbs: this.this_log.start_hobbs,
            end_hobbs: this.this_log.end_hobbs,
            ins_time: this.this_log.ins_time,
            invoice: this.this_log.invoice,
            from_city: this.this_log.from_city,
            to_city: this.this_log.to_city,
            pilot_in_command_id: this.this_log.pilot_in_command_id,
            first_officer_id: this.this_log.first_officer_id,
            aircraft_id: this.call_current_event().aircraft_id,
            event_id: this.call_current_event().id,
            notes: this.this_log.notes
          }
        }).then(response => {
          let this_event = this.$parent.$parent.$children[0];
          let id_event = this_event.current_event.id;
          this_event.cancel_display(id_event);
          bus.$emit('selectedEventList', id_event)
          M.toast({html: 'Created Log.'});
          this.$scrollTo('#display_form');
          this.incall = false;
        }, response => {
          this.displayErrorMix(response)
          console.log(response)
          this.incall = false;
        });
      },
      updateLog: function() {
        if (this.incall) return
        this.incall = true
        var flight_date = this.validateTime();
        this.$http.put('/en/aircraft_logs/' + this.this_log.id, {
          aircraft_log: {
            flight_date: flight_date,
            start_tach: this.this_log.start_tach,
            end_tach: this.this_log.end_tach,
            start_hobbs: this.this_log.start_hobbs,
            end_hobbs: this.this_log.end_hobbs,
            ins_time: this.this_log.ins_time,
            invoice: this.this_log.invoice,
            from_city: this.this_log.from_city,
            to_city: this.this_log.to_city,
            pilot_in_command_id: this.this_log.pilot_in_command_id,
            first_officer_id: this.this_log.first_officer_id,
            aircraft_id: this.call_current_event().aircraft_id,
            event_id: this.call_current_event().id,
            notes: this.this_log.notes
          }
        }).then(response => {
          let this_event = this.$parent.$parent.$children[0];
          let id_event = this_event.current_event.id;
          this_event.cancel_display(id_event);
          bus.$emit('selectedEventList', id_event)
          M.toast({html: 'Updated Log.'});
          this.$scrollTo('#display_form');
          this.incall = false;
        }, response => {
          this.displayErrorMix(response)
          console.log(response)
          this.incall = false;
        });
      },
      destroy_log: function() {
        if (this.incall) return
        this.incall = true
        if (confirm(this.$t(`${this.cas}.share.are_u_sure`))) {
          var log = this.this_log
          this.$http.delete( '/en/aircraft_logs/' + log.id,{
            aircraft_log: { id: log.id  }
          }).then(response => {
            var removeIndex = this.$parent.current_logs.indexOf(this.this_log)
            this.$parent.current_logs.splice(removeIndex, 1)
            M.toast({html: 'Destroyed Log.'})
            this.$scrollTo('#display_form');
            this.incall = false;
          }, response => {
            console.log('nope')
            console.log(response)
            this.incall = false;
          })
        }
      },
      // TODO: otro para cristhian
      event_is_accepted: function() {
          if (this.call_current_event().is_accepted){
            return false
          } else {
            return true
          }
      },
      validateTime: function(){
        var from_date = '';

        if (this.this_log.flight_date) {
          from_date = this.$moment(new Date(this.this_log.flight_date));
          return from_date
        }
      }
    },
    mixins: [applicationMixin]

  }
</script>
