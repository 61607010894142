<template>
  <div class="warning_level tooltipped" data-position="left" :data-tooltip="this.warning_text">
    <i v-show="this.warning_level > 0" :class="'material-icons ' + what_level()">warning</i>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        // warning_level: null
      }
    },
    props: ['warning_level', 'warning_text'],
    mounted: function() {
      var tooltipped   = document.querySelectorAll('.tooltipped');
      if (tooltipped)   var tooltippedInstances  = M.Tooltip.getInstance(tooltipped);
      if (tooltippedInstances) tooltippedInstances.destroy();

      if (tooltipped) var tooltippedInstances = M.Tooltip.init(tooltipped, {});
    },
    methods: {
      what_level: function(){
        switch(this.warning_level) {
          case 0: {
            return ''
            break;
          }
          case 1: {
            return 'red-text text-darken-2'
            break;
          }
          case 2: {
            return 'orange-text text-darken-2'
            break;
          }
          case 3: {
            return 'amber-text text-lighten-2'
            break;
          }
          default: {
            return ''
            break;
          }
        }
      }
    }
  }
</script>
