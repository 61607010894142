
<template>
  <div id="logs" v-show="show_logs" class="row">
    <div class="card-action sub-card-action_tawa active">
      <span class="card-title">{{ $t(`${cas}.aircraft_log.create_new_log`) }}</span>
      <div class="new_object"
           v-if="event_is_accepted()"
           v-can.create.AircraftLog>
        <a class="waves-effect waves-light btn right new_object__btn__xsmall"
         @click="addNewLog">
           <i class="material-icons new_object__btn__i new_object__btn__i__xsmall right medium white-text">add</i>
        </a>
      </div>
    </div>

    <div v-for="(log, index) in current_logs" class="col s12 no-border no-border-logs">

      <div :id="`log-id-${log.id}`" v-bind:class="['log', 'card-action', 'sub-card-action_tawa', { even: isEven(index) }]" @click='openDetail(index, log)'>
        <span class="card-title" v-if="log.event">EV{{ log.event.id }}AL{{ log.id }} - {{ date_display_log(log.flight_date) }} / {{ !!log.pilot_in_command ? log.pilot_in_command.name+',':'' }} {{ !!log.first_officer ? log.first_officer.name:'' }} / Invoice: {{log.invoice}}</span>
        <span class="card-title" v-else>{{ $t(`${cas}.aircraft_log.creating_new_log`) }}</span>
      </div>
      <log :log='log'
           :pilots='pilots'
           :ca='ca'
           ></log>
    </div>
    <div class="col s12 no-border no-border-logs" v-if="current_logs.length > 0">
      <div id="totals" class="card-action sub-card-action_tawa active">
        <span class="card-title right-align">
          <span style="float: left;">{{ $t(`${cas}.aircraft_log.total_sum`) }}</span>
          <span v-if="is_it_sim()">
            Hobbs: {{sum_data.hobbs}}
            <span v-if="is_it_school()">
              , Inst Time: {{sum_data.ins_time}}
            </span>
          </span>
          <span v-else>
            {{ $t(`${cas}.aircraft_log.hobbs`) }}: {{sum_data.hobbs}}, {{ $t(`${cas}.aircraft_log.tach`) }}: {{sum_data.tach}}
            <span v-if="is_it_school()">
              , {{ $t(`${cas}.aircraft_log.inst_time`) }}: {{sum_data.ins_time}}
            </span>
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import { bus } from '../../packs/event_bus';
  import Log from './log';
  import VueScrollTo from 'vue-scrollto';
  import { applicationMixin } from '../../mixins/application_mixin';

  import 'select2'


  export default {
    components: {
      log: Log
    },
    props: ['pilots', 'ca'],
    data() {
      return {
        current_logs: [],
        show_logs: false,
        sum_data: { hobbs: null, tach: null, ins_time: null },
        cas: this.ca.account_type,
      }
    },
    created() {
      bus.$on('logsUpdate', (updated_logs) => {
        this.updateLogs(updated_logs)
      });
    },
    methods: {
      is_it_school: function () {
        return this.cas == 'school'
      },
      isEven: function(index) {
        return !(index % 2)
      },
      is_it_sim: function() {
        return this.$parent.$children[0].current_event.aircraft.sim
      },
      updateLogs: function(logs) {
        this.current_logs = logs;
        if (!!this.$parent.$children[0].current_event && !!this.$parent.$children[0].current_event.aircraft_id) {
          this.show_logs = this.$parent.$children[0].current_event

          this.sum_data = {
            hobbs: Object.keys(logs).map(l => parseFloat(logs[l].end_hobbs) * 10 - parseFloat(logs[l].start_hobbs) * 10),
            tach: Object.keys(logs).map(l => parseFloat(logs[l].end_tach) * 10 - parseFloat(logs[l].start_tach) * 10),
            ins_time: Object.keys(logs).map(l => parseFloat(logs[l].ins_time) * 10),
          }

          Object.keys(this.sum_data).forEach(function (k) {
            let add = (a, b) => a + b
            if (this.sum_data[k].length > 0) {
              this.sum_data[k] = this.sum_data[k].reduce(add) / 10
            } else {
              this.sum_data[k] = 'No Data'
            }
          }.bind(this));

          this.$forceUpdate();
        } else {
          this.show_logs = null
        }
      },
      openDetail: function(index, log) {
        let is_open = this.$children[index].details_show
        this.closeAllLogs()
        if (!is_open) this.$children[index].details_show = true
        this.openLogDetail(is_open, `log-id-${log.id}`)
        VueScrollTo.scrollTo(`#log-id-${log.id}`)
      },
      date_display_log: function(flight_date) {
          if (flight_date) return this.dateTimeHandlerMix(flight_date, 'date')
      },
      date_for_log: function() {
        let this_event = this.$parent.$children[0].current_event
        if (this_event.from_flight_date_date == this_event.to_flight_date_date) {
          return this_event.from_flight_date_date
        } else {
          return null
        }
      },
      latest_log: function() {
        //if aicraft
        var id = this.$parent.$children[0].current_event.aircraft_id
        this.$http.patch('/en/aircraft_logs/latest_time_log', {
          aircraft: { id: id }
        }).then(response => {
          if (response.body.length === 0) return
          this.current_logs[this.current_logs.length - 1].start_tach = response.body[0].end_tach
          this.current_logs[this.current_logs.length - 1].end_tach = response.body[0].end_tach
          this.current_logs[this.current_logs.length - 1].start_hobbs = response.body[0].end_hobbs
          this.current_logs[this.current_logs.length - 1].end_hobbs = response.body[0].end_hobbs

        });
      },
      addNewLog: function() {
        this.closeAllLogs()
        let aircraft = this.$parent.$children[0].current_event.aircraft

        if (this.current_logs.filter(l => l.id === null).length === 0) {
          this.current_logs.push({
            id: null,
            flight_date: this.date_for_log(),
            start_tach: aircraft.tach_time ||= 0,
            end_tach: aircraft.tach_time ||= 0,
            start_hobbs: aircraft.hobbs_time ||= 0,
            end_hobbs: aircraft.hobbs_time ||= 0,
            ins_time: 0,
            invoice: 0,
            from_city: null,
            to_city: null,
            pilot_in_command_id: null,
            first_officer_id: null,
            notes: null,
            finalized: null,
            creator_id: null,
            event_id: null,
            aircraft: null,
          })
          this.latest_log()
        } else {
          this.openLogDetail(false, `log-id-null`)
          this.$children[this.$children.length-1].details_show = true
          VueScrollTo.scrollTo('#log-id-null')
        }
      },
      closeAllLogs: function() {
        var logClassElements = document.getElementsByClassName('log');
        [].forEach.call(logClassElements, function(el) { el.classList.remove("active") });
        this.$children.forEach(function(instance) { instance.details_show = false });

      },
      openLogDetail: function(is_open, id) { //   !is_open is boolean
        var title_el = document.getElementById(id);
        if (!is_open) title_el.className += " active";
      },
      event_is_accepted: function() {
        if (!this.$parent.$children[0].current_event) return
        let this_event = this.$parent.$children[0].current_event
        if (this_event.is_accepted){
          return false
        } else {
          return true
        }
      },
    },
    mixins: [applicationMixin]
  }
</script>
