<!-- TODO: la busqueda de eventos no funciona si no se pone el to date, el cual debe ser opcional -->
<template>
  <div id="flight_table_not_accepted_card">
    <div class="new_object">
      <a class="waves-effect waves-light btn right new_object__btn"
        @click="change_display_type('time_line')">
        <i class="material-icons new_object__btn__i right medium white-text">event_note</i>
      </a>
    </div>
    <div id="filter_list_event" class="acceptance_hover_displey new_object__right"
         @mouseover="its_hover_filter"
         @mouseout="accept_hover_filter = 0">
      <a class="waves-effect waves-light btn right new_object__btn"
         @click="accepted_events = 'all'"
         v-show="accept_hover_filter || this.$parent.state_title == $t(`${cas}.event.all_events`)">
         <i class="material-icons new_object__btn__i right medium white-text">all_inclusive</i>
      </a>
      <a class="waves-effect waves-light btn right new_object__btn"
         @click="accepted_events = 'accepted'"
         v-show="accept_hover_filter || this.$parent.state_title == $t(`${cas}.event.authorized_events`)">
         <i class="material-icons new_object__btn__i right medium white-text">assignment_turned_in</i>
      </a>
      <a class="waves-effect waves-light btn right new_object__btn"
         @click="accepted_events = 'not_accepted'"
         v-show="accept_hover_filter || this.$parent.state_title == $t(`${cas}.event.programed_events`)">
         <i class="material-icons new_object__btn__i right medium white-text">assignment</i>
      </a>
    </div>
    <div class="">
      <div class="col s12 search_fields no-border">
        <div class="input-field col s6 m5 l5">
          <input id="search_from_date" type="text" v-model="search.search_from_date" class="validate date search_from_date">
          <label for="search_from_date">{{ $t(`${cas}.event.from_date`) }}</label>
          <span class="helper-text error_text" :data-error="error_text.search_from_date"/>
        </div>
        <div class="input-field col s6 m5 l5">
          <input id="search_to_date" type="text" v-model="search.search_to_date" class="validate date search_to_date">
          <label for="search_to_date">{{ $t(`${cas}.event.to_date_optional`) }}</label>
          <span class="helper-text error_text" :data-error="error_text.search_to_date"/>
        </div>
        <a id="search_events_btn" class="button_search waves-effect waves-light btn col s12 m2 l2" @click="search_events(search.search_from_date, search.search_to_date)">{{ $t(`${cas}.share.search`) }}</a>
        <div id="resources_search_dp" class="input-field col s12">
          <select multiple v-model.lazy="selected_groups" >
            <optgroup :label="$t(`${cas}.event.aircraft`)">
              <option v-for="aircraft in aircrafts" :value="'aircraft-' + aircraft.id"
              >{{ aircraft.registration }} - {{ aircraft.aircraft_type }}</option>
            </optgroup>
            <optgroup :label="$t(`${cas}.aircraft_log.first_officer.vue.label`)">
              <option v-for="pilot in pilots" :value="'user-' + pilot.id" v-if="pilot.level == 3"
              > {{ pilot.name }}</option>
            </optgroup>
            <optgroup :label="$t(`${cas}.aircraft_log.pilot_in_command.vue.label`)">
              <option v-for="pilot in pilots" :value="'user-' + pilot.id" v-if="pilot.level == 2 || pilot.level == 1"
              > {{ pilot.name }}</option>
            </optgroup>
          </select>
          <label>{{ $t(`${cas}.event.select_resources`) }}</label>
        </div>
      </div>
    </div>
    <table class="highlight striped" id="not_accepted_aircraft" v-if="current_events">
      <thead>
        <tr>
          <th>{{$t(`${cas}.event.aircraft`)}}</th>
          <th>{{$t(`${cas}.form.from_flight_date`)}}</th>
          <th>{{$t(`${cas}.form.to_flight_date`)}}</th>
          <th>{{$t(`${cas}.event.participants`)}}</th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(event, index) in filterdLogs"
            @click='show_detailed_flight(event.id)'
            :class="'event_' + event.id + ' event_list'">
          <td> {{ !!event.aircraft ? event.aircraft.aircraft_type : "" }} - {{ !!event.aircraft ? event.aircraft.registration : "" }} </td>
          <td> {{ dateTimeHandlerMix(event.from_flight_date, 'date_time') }} </td>
          <td> {{ dateTimeHandlerMix(event.to_flight_date, 'date_time') }} </td>
          <td> {{ event.user_list }} </td>
          <td><a v-can.manage.all :href="`/events/${event.id}`"><i class="material-icons">pageview</i></a></td>
          <td>
            <warningLogos :warning_level="event.warning_level" :warning_text="event.warning_text"></warningLogos>
          </td>
          <td class="acceptance_hover" @mouseover="its_hover(event.id)" @mouseout="accept_hover = 0">
            <i class="material-icons acceptance_hover_icon right" v-if="!(event.is_accepted)" >more_vert</i>
            <a class="waves-effect waves-light btn right accep_flight"
               v-if="!(event.is_accepted)"
               @click='accepted_flight(event)'
               v-show="accept_hover === event.id"
               v-can.event_approval.Event>
               {{$t(`${cas}.event.approve`)}}
             </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import { bus } from '../../packs/event_bus';
  import warningLogos from '../shared/warning_logo';
  import { applicationMixin } from '../../mixins/application_mixin';
  import { flightFormMixin } from '../../mixins/flight_form_mixin';


  export default {
    components: {
      warningLogos: warningLogos
    },
    props: ['aircrafts', 'pilots', 'events', 'ca'],
    data() {
      return {
        cas: this.ca.account_type,
        current_events: null,
        accept_hover: 0,
        accept_hover_filter: false,
        search: {
          ids: [],
          search_from_date: '',
          search_to_date: '',
        },
        type_display: null,
        accepted_events: 'not_accepted',
        error_text: {},
        picker: {
          fromDatePikerinstances: null,
          toDatePikerinstances: null,
        },
      }
    },
    created() {
      this.$parent.state_title = 'All Events';
      bus.$on('notAcceptedNewLog', (events) =>{
        this.update_log(events)
      })
      bus.$on('cleanListEventSelected',() =>{
        var eventElements = document.getElementsByClassName('event_list');
        [].forEach.call(eventElements, function(el) { el.classList.remove("active") });
      })
      bus.$on('selectedEventList',(id) =>{
          var title_el = document.getElementsByClassName(`event_${id}`);
          title_el[0].className += " active";
      })
      this.current_events = this.events
    },
    mounted: function() {
      var elems = document.querySelectorAll('select');
      var instances = M.FormSelect.init(elems, {});

      // materializecss DatePicker
      if (this.picker.fromDatePikerinstances === null) {
        var searchFromDatePiker = document.querySelectorAll('#search_from_date');
        var searchToDatePiker = document.querySelectorAll('#search_to_date');
        let vm = this

        this.picker.fromDatePikerinstances = M.Datepicker.init(searchFromDatePiker, {
          showClearBtn: true,
          autoClose: true,
          onClose: function() {
            vm.search.search_from_date = this.$el[0].value
            vm.picker.toDatePikerinstances.options.minDate = vm.time_generator(vm.search.search_from_date);
          }
        })[0];
        this.picker.toDatePikerinstances = M.Datepicker.init(searchToDatePiker, {
          showClearBtn: true,
          autoClose: true,
          onClose: function() {
            vm.search.search_to_date = this.$el[0].value
            vm.picker.fromDatePikerinstances.options.maxDate = vm.time_generator(vm.search.search_to_date);
          }
        })[0];
      }
    },
    computed:{
      selected_groups: {
        get: function() {
          return this.search.ids
        },
        set: function (ids) {
          this.search.ids = ids
        }
      },
      filterdLogs: function() {
        var current_state_list = this.show_state_type()
        if (this.search.ids.length === 0 && this.search.search_from_date === "" && this.search.search_to_date === "") {
          return current_state_list
        } else {
          var pilot_plane_list = this.by_pilot_and_plane(current_state_list)
          return this.by_pilot_and_plane(current_state_list)
        }
      },
    },
    methods: {
      search_events: function(from_date, to_date) {
        this.$http.get(`/en/events?utf8=✓&from=${from_date}&to=${to_date}`).then(response => {
          this.$parent.$parent.current_events = response.body.events;
          bus.$emit('cancelDisplayNoAjax');
          bus.$emit('notAcceptedNewLog', this.$parent.$parent.current_events);
          bus.$emit('AcceptedNewLog', this.$parent.$parent.current_events);
          bus.$emit('formAcceptedNewLog', this.$parent.$parent.current_events);
        });
      },
      by_time: function(pilot_plane_list){
        var pilot_plane_time = []

        if (!(this.search.search_from_date === "") && !(this.search.search_to_date === "")) {
          pilot_plane_time.push(pilot_plane_list.filter(event =>
            this.$moment.utc(new Date(event.from_flight_date)).local().format() > this.$moment.utc(new Date(this.search.search_from_date)).local().startOf('day').format() &&
            this.$moment.utc(new Date(event.to_flight_date)).local().format() < this.$moment.utc(new Date(this.search.search_to_date)).local().endOf('day').format()
          ))
        } else if (!(this.search.search_from_date === "") && (this.search.search_to_date === "")) {
          pilot_plane_time.push(pilot_plane_list.filter(event =>
            this.$moment.utc(new Date(event.from_flight_date)).format() > this.$moment.utc(new Date(this.search.search_from_date)).local().startOf('day').format()
          ))
        } else {
          return this.by_pilot_and_plane(current_state_list)
        }

        pilot_plane_time = [].concat.apply([],pilot_plane_time);
        return [].concat.apply([],pilot_plane_time);
      },
      by_pilot_and_plane: function(current_state_list) {
        var arraySearch = { aircraft: [], user: [] };
        var list = [];

        this.search.ids.forEach(function(id){
          let current_selection = id.split('-');
          arraySearch[current_selection[0]].push(current_selection[1])
        })

        if (arraySearch.aircraft.length > 0) {
          arraySearch.aircraft.forEach(function(aircraft_id){
            list.push(current_state_list.filter(event => event.aircraft_id === parseInt(aircraft_id)))
            list = [].concat.apply([],list);
          });
        } else {
          list.push(current_state_list);
          list = [].concat.apply([],list);
        }
        if (arraySearch.user.length > 0) {
          arraySearch.user.forEach(function(user_id){
            list.push(list.filter(event => event.users.some(user => user.id === parseInt(user_id))))
            list = [].concat.apply([],list);
          });
        } else {
          list.push(current_state_list);
          list = [].concat.apply([],list);
        }

        return list.reduce(function(acc, el, i, arr) {
                  if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
                }, []);
      },
      show_state_type: function(){
        switch (this.accepted_events) {
          case 'not_accepted':
            this.$parent.state_title = this.$t(`${this.cas}.event.programed_events`);
            return this.current_events.filter(event => event.is_accepted === false)
            break;
          case 'accepted':
            this.$parent.state_title = this.$t(`${this.cas}.event.authorized_events`);
            return this.current_events.filter(event => event.is_accepted === true)
            break;
          default:
            this.$parent.state_title = this.$t(`${this.cas}.event.all_events`);
            return this.current_events
        }
      },
      update_log: function(events) {
        this.current_events = events;
      },
      mapper: function(str) {
        var o = {};
        var strArr = []
        strArr = str.split(":");
        o[strArr[0].trim()] = strArr[1].trim();
        return o;
      },
      its_hover: function(id) {
        this.accept_hover = id;
      },
      its_hover_filter: function() {
        this.accept_hover_filter = true;
      },
      accepted_flight: function(flight) {
        this.$http.put( '/en/events/' + flight.id + '/event_approval',{
          event: {
            is_accepted: true
          }
        }).then(response => {
          this.findCurrentFlightMix(flight.id).is_accepted = true
          var search_from_date, search_to_date
          if (!!this.search) {
            search_from_date = this.search.search_from_date
            search_to_date = this.search.search_to_date
          }

          bus.$emit('displayUpdatedLogs', null, search_from_date, search_to_date);
        }, response => {
          this.displayErrorMix(response)
          console.log('nope')
          console.log(response)
        })
      },
      show_detailed_flight: function(id) {
        bus.$emit('displayEvent', id);
        bus.$emit('cleanListEventSelected')
        bus.$emit('selectedEventList', id)
      },
      change_display_type: function(type_display) {
        bus.$emit('selectedDisplayType', type_display)
      },
    },
    mixins: [applicationMixin, flightFormMixin]
  }
</script>
