<template>
    <div>
      <eventindex :title='{"title": $t(`${cas}.event.events`)}'>
        <eventsdisplay slot="table"
                                :events="current_events"
                                :aircrafts="current_init_aircrafts"
                                :pilots="current_init_pilots"
                                :ca='current_account'
                                v-if="display_type === 'programed'"
                                ></eventsdisplay>
        <timeline slot="table"
                  :aircrafts="current_init_aircrafts"
                  :pilots="current_init_pilots"
                  :line_events="current_time_line_events"
                  :ca='current_account'
                  v-else-if="display_type == 'time_line'"
                  ></timeline>
      </eventindex>

      <eventindex :title='{"title": $t(`${cas}.form.create_new_event`)}' id="display_form">
        <eventform slot="table"
                    :events="current_events"
                    :aircrafts="current_init_aircrafts"
                    :pilots="current_init_pilots"
                    :ca='current_account'></eventform>
        <logs slot="table2"
              :pilots="current_init_pilots"
              :ca='current_account'
              ></logs>
      </eventindex>
    </div>
</template>

<script>
  import EventIndex from '../shared/index.vue';
  import EventsDisplay from'./events_display.vue';
  import TimeLine from'./time_line.vue';
  import EventForm from'./form.vue';
  import Logs from'./logs';
  import { bus } from '../../packs/event_bus';

  export default {
    components: {
      'eventindex': EventIndex,
      'eventsdisplay': EventsDisplay,
      'eventform': EventForm,
      'timeline': TimeLine,
      'logs': Logs,
    },
    props:['events', 'init_aircrafts', 'init_pilots', 'current_account'],
    created() {
      bus.$on('displayUpdatedLogs',(id = null, from_date = '', to_date = '') =>{
        this.eventUpdates(id, from_date, to_date)
      });
      bus.$on('selectedDisplayType',(type_display) =>{
        bus.$emit('cancelDisplayNoAjax');
        this.displayType(type_display)
      });
      this.current_events = this.events
      this.current_init_aircrafts = this.init_aircrafts
      this.current_init_pilots = this.init_pilots
      this.current_time_line_events = null
    },
    data() {
      return {
        cas: this.current_account.account_type,
        current_events: null,
        current_init_new_event: null,
        current_init_aircrafts: null,
        current_init_aircrafts_events: null,
        current_time_line_events: null,
        display_type: 'time_line',
      }
    },
    methods: {
      eventUpdates: function(flight_id, from_date, to_date){
        this.$http.get(`/en/events?utf8=✓&from=${from_date}&to=${to_date}`).then(response => {
          this.current_events = response.body.events;
          this.current_time_line_events = response.body.time_line_events;
          bus.$emit('updateTimeLine', response.body.time_line_events );
          bus.$emit('notAcceptedNewLog', this.current_events);
          bus.$emit('AcceptedNewLog', this.current_events);
          bus.$emit('formAcceptedNewLog', this.current_events);
          if (typeof flight_id == 'number') {bus.$emit('displayEvent',(flight_id))}
        });
      },
      displayType: function(type_display) {
        this.display_type = type_display;
      }
    }
  }
</script>
