export const flightFormMixin = {
  methods: {
    resetDatePikerMix: function () {
      if (this.picker.fromDatePikerinstances) {
        this.picker.fromDatePikerinstances.destroy();
        this.picker.fromDatePikerinstances = null;
      }

      if (this.picker.fromTimePikerinstances) {
        this.picker.fromTimePikerinstances.destroy();
        this.picker.fromTimePikerinstances = null;
      }

      if (this.picker.toDatePikerinstances) {
        this.picker.toDatePikerinstances.destroy();
        this.picker.toDatePikerinstances = null;
      }

      if (this.picker.toTimePikerinstances) {
        this.picker.toTimePikerinstances.destroy();
        this.picker.toTimePikerinstances = null;
      }
    },

    findCurrentFlightMix: function (id) {
      return this.current_events.find(flightLog => flightLog.id === id);
    },
  },
};
