export const applicationMixin = {
  methods: {
    displayErrorMix: function (response) {
      if (response.status == 422) {
        Object.entries(response.body).forEach(([key, value]) => {
          if (key === 'vue_errors') {
            this.ModalMessengerMix(value);
          } else {
            var inputClassElem = document.getElementsByClassName(key);
            var notSuportedList = ['aircraft', 'pilot_in_command', 'first_officer'];
            if (inputClassElem.length > 1 && !notSuportedList.includes(key)) {
              Object.entries(inputClassElem).forEach((el) => {
                el[1].className += ' invalid';
              });
            } else {
              inputClassElem[0].className += ' invalid';
            }

            this.error_text[key] = '';
            this.error_text[key] = `${value}`;
          }
        });
      } else if (response.status == 401) {
        this.ModalMessengerMix([response.body.message]);
      } else if (response.status == 403) {
        this.ModalMessengerMix([response.bodyText + '- You don\'t have permission to access this action.']);
      }
      this.$forceUpdate();
      console.log('nope');
      console.log(response);
    },

    time_generator: function (value) {
      if (value === '') {
        return '';
      } else {
        return this.$moment(value, ['DDMMMMY', 'MMMMDDY']);
      }
    },

    ModalMessengerMix: function (messages) {
      var modal = document.querySelectorAll('#modal');
      var modalInstances = M.Modal.getInstance(modal[0], {});
      var modal_content = document.getElementById('modal-content');
      modal_content.innerHTML = '';
      messages.forEach((text) => {
        var ul = document.createElement('ul');
        var li = document.createElement('li');
        li.appendChild(document.createTextNode('-' + text));
        ul.appendChild(li);
        modal_content.appendChild(ul);
      });
      modalInstances.open();
    },

    dateTimeHandlerMix: function (date, action) {
      if (action === 'date') {
        var dateOptions = {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        };
        return new Date(date).toLocaleDateString('en-US', dateOptions);
      } else if (action === 'time') {
        var timeOption = {
          hour: '2-digit',
          minute: '2-digit',
        };
        return new Date(date).toLocaleTimeString('en-US', timeOption);
      } else if (action === 'date_time') {
        var dateTimeOption = {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        };
        return new Date(date).toLocaleTimeString('en-US', dateTimeOption);
      }
    },
    arraysEqualMix: function(arr1, arr2) {
      if(arr1.length !== arr2.length)
          return false;
      for(var i = arr1.length; i--;) {
          if(Number(arr1[i]) !== Number(arr2[i]))
              return false;
      }
      return true;
    },
    findObjectByIdMix: function (objects, id) {
      return objects.find(object => object.id === id);
    },
  },
};
