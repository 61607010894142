<template>
  <div id="form_card">
    <div class="row" v-if="current_event" style="margin-top: 20px;">
      <div class="acceptance_hover_displey"
           v-if="current_event.id === null">
        <a class="waves-effect waves-light btn right accep_flight acceptance_hover_displey__btn acceptance_hover_displey__btn--cancel"
           v-scroll-to="'#flight_table_not_accepted_card'"
           @click="cancel_display">
           {{ $t(`${cas}.share.cancel`) }}
        </a>
        <span v-can.create.Event>
          <a class="waves-effect waves-light btn right accep_flight acceptance_hover_displey__btn acceptance_hover_displey__btn--create"
            @click="create_flight"
            :disabled="incall">
            {{ $t(`${cas}.share.create`) }}
          </a>
        </span>
      </div>
      <div class="acceptance_hover_displey"
           v-else>
        <a class="waves-effect waves-light btn right accep_flight acceptance_hover_displey__btn acceptance_hover_displey__btn--cancel"
           v-scroll-to="'#flight_table_not_accepted_card'"
           @click="cancel_display">
           {{ $t(`${cas}.share.cancel`) }}
        </a>
        <span v-if="$can('update', 'Event') && !(current_event.is_accepted)">
          <a class="waves-effect waves-light btn right accep_flight acceptance_hover_displey__btn  acceptance_hover_displey__btn--update"
            @click="update_flight"
            :disabled="incall">
            {{ $t(`${cas}.share.update`) }}
          </a>
        </span>
        <span v-if="$can('destroy', 'Event') && !(current_event.is_accepted)">
          <a class="waves-effect waves-light btn right accep_flight acceptance_hover_displey__btn acceptance_hover_displey__btn--destroy"
          @click="destroy_flight"
          v-scroll-to="'#flight_table_not_accepted_card'"
          :disabled="incall">
          {{ $t(`${cas}.share.destroy`) }}
        </a>
        </span>
      </div>
      <form class="col s12 no-border">
          <div class="input-field col s12 m6 aircrafts">
            <select v-model="current_event.aircraft_id" id="aircraft">
              <option value="">{{ $t(`${cas}.share.none_selected`) }}</option>
              <option v-for="aircraft in aircrafts" :value="aircraft.id"
              >{{ aircraft.registration }} - {{ aircraft.aircraft_type }}</option>
            </select>
            <label>{{ $t(`${cas}.form.select_aircraft`) }}</label>
          </div>
          <div class="input-field col s12 m6 pilots">
            <select v-model="selected_users" id="users" multiple >
              <optgroup :label="$t(`${cas}.form.pilots.first_officer`)">
                <option v-for="pilot in pilots" :value="pilot.id" v-if="pilot.level == 3"> {{pilot.name}}</option>
              </optgroup>
              <optgroup :label="$t(`${cas}.form.pilots.pilot_in_command`)">
                <option v-for="pilot in pilots" :value="pilot.id" v-if="pilot.level == 2 || pilot.level == 1"> {{pilot.name}}</option>
              </optgroup>
            </select>
            <label>{{ $t(`${cas}.form.pilots.select_pilots`) }}</label>
          </div>
          <div class="input-field col s6 m3">
            <input id="from_flight_date" type="text" v-model="current_event.from_flight_date_date" class="validate date from_flight_date">
            <label for="from_flight_date">{{ $t(`${cas}.form.from_flight_date`) }}</label>
            <span class="helper-text error_text" :data-error="error_text.from_flight_date"/>
          </div>
          <div class="input-field col s6 m3">
            <input id="from_flight_date_time" type="text" v-model="current_event.from_flight_date_time" class="validate time from_flight_date">
            <label for="from_flight_date_time">{{ $t(`${cas}.form.from_flight_time`) }}</label>
            <span class="helper-text error_text" :data-error="error_text.from_flight_date"/>
          </div>
          <div class="input-field col s6 m3">
            <input id="to_flight_date" type="text" v-model="current_event.to_flight_date_date" class="validate date to_flight_date">
            <label for="to_flight_date">{{ $t(`${cas}.form.to_flight_date`) }}</label>
            <span class="helper-text error_text" :data-error="error_text.to_flight_date"/>
          </div>
          <div class="input-field col s6 m3">
            <input id="to_flight_date_time" type="text" v-model="current_event.to_flight_date_time" class="validate time to_flight_date">
            <label for="to_flight_date_time">{{ $t(`${cas}.form.to_flight_time`) }}</label>
            <span class="helper-text error_text" :data-error="error_text.to_flight_date"/>
          </div>
          <div class="input-field col s12">
            <textarea id="notes" type="text" v-model="current_event.notes" class="validate notes materialize-textarea"></textarea>
            <label for="notes">{{ $t(`${cas}.form.notes`) }}</label>
            <span class="helper-text error_text" :data-error="error_text.notes"/>
          </div>
      </form>
    </div>
    <div class="new_object"  v-else>
      <div v-can.create.Event>
        <a class="waves-effect waves-light btn right new_object__btn"
           @click="new_flight_object">
           <i class="material-icons new_object__btn__i right medium white-text">add</i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from '../../packs/event_bus';
import { applicationMixin } from '../../mixins/application_mixin';
import { flightFormMixin } from '../../mixins/flight_form_mixin';
import 'select2'

export default {
  props: ['events', 'aircrafts', 'pilots', 'ca'],
  data() {
    return {
      error_text: {},
      current_event: null,
      its_create: true,
      current_events: this.events,
      incall: false,
      picker: {
        fromDatePikerinstances: null,
        fromTimePikerinstances: null,
        toDatePikerinstances: null,
        toTimePikerinstances: null
      },
      cas: this.ca.account_type,
      time_interval: this.ca.time_flight_interval,
    }
  },
  computed: {
    selected_users: {
      get: function () {
        if (this.current_event != null && this.current_event.users != null) {
          return this.current_event.users.map(u => u.id)
        } else {
          return []
        }
      },
      set: function (ids) {
        this.current_event.users = []
        ids.forEach(function(id) {
          this.current_event.users.push(this.findObjectByIdMix(this.pilots, id))
        }.bind(this));
      }
    }
  },
  created() {
    bus.$on('displayEvent',(flight_id) =>{
      let event = this.findCurrentFlightMix(parseInt(flight_id, 10))
      this.buildFlight(event)
      bus.$emit('logsUpdate', event.aircraft_logs);
    });
    bus.$on('displayNewLogForm',() =>{
      this.new_flight_object()
    });
    bus.$on('formAcceptedNewLog', (events) =>{
      this.update_log(events)
    });
    bus.$on('cancelDisplayNoAjax', () =>{
      this.cancel_display_no_ajax()
    });
    bus.$on('scrollDisplayForm', () =>{
      this.$scrollTo('#display_form');
    });
  },
  updated: function() {
    M.updateTextFields();
    if (this.current_event) {

      var vm = this
      $(vm.$el).find('.aircrafts select').select2().val(vm.current_event.aircraft_id).trigger('change').on('change', function () {
        if (vm.current_event.aircraft_id !== Number($(this).val())) {
          vm.current_event.aircraft_id = $(this).val()
        }
      })

      $(vm.$el).find('.pilots select').select2().val(this.selected_users).trigger('change').on('change', function () {
        if (!vm.arraysEqualMix(vm.current_event.users.map(u => u.id), $(this).val())) {
          vm.current_event.users = []
          $(this).val().forEach(function(id) {
            vm.current_event.users.push(vm.findObjectByIdMix(vm.pilots, Number(id)))
          })
        }
      })

      var dropdownClassElem = document.getElementsByClassName("dropdown-trigger");
      dropdownClassElem[0].setAttribute("id", "aircraft")

      // materializecss DatePicker
      if (this.picker.fromDatePikerinstances === null ) {
        var fromDatePiker = document.querySelectorAll('#from_flight_date');
        var fromTimePiker = document.querySelectorAll('#from_flight_date_time');
        var toDatePiker = document.querySelectorAll('#to_flight_date');
        var toTimePiker = document.querySelectorAll('#to_flight_date_time');
        let vm = this

        this.picker.fromDatePikerinstances = M.Datepicker.init(fromDatePiker, {
          autoClose: true,
          minDate: new Date(Date.now()),
          onClose: function() {
            vm.current_event.from_flight_date_date = this.$el[0].value
            if (!vm.current_event.to_flight_date_date) vm.current_event.to_flight_date_date= this.$el[0].value
            vm.picker.toDatePikerinstances.options.minDate = new Date(vm.current_event.from_flight_date_date);
            vm.picker.toDatePikerinstances.setDate(new Date(vm.current_event.from_flight_date_date));
          }
        })[0];
        this.picker.fromTimePikerinstances = M.Timepicker.init(fromTimePiker, {
          autoClose: true,
          defaultTime: '07:00 AM',
          onCloseEnd: function() {
            vm.current_event.from_flight_date_time = this.$el[0].value

            if (vm.current_event.from_flight_date_date && !vm.current_event.to_flight_date_time) {
              let date_temp = new Date( vm.current_event.from_flight_date_date + ', ' + vm.current_event.from_flight_date_time )

              if (vm.current_event.from_flight_date_date == vm.current_event.to_flight_date_date && vm.time_interval) {
                let time_interval = new Date(vm.time_interval)

                date_temp.setHours(date_temp.getHours() + time_interval.getHours())
                date_temp.setMinutes(date_temp.getMinutes() + time_interval.getMinutes())
              } else if (vm.current_event.from_flight_date_date == vm.current_event.to_flight_date_date) {
                date_temp.setHours(date_temp.getHours() + 1)
              }

              vm.current_event.to_flight_date_time = date_temp.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true})
              if (vm.current_event.from_flight_date_date == vm.current_event.to_flight_date_date) {
                vm.current_event.to_flight_date_date = date_temp.toLocaleString( 'en-US', { month: 'short', day: 'numeric', year: 'numeric'})
              }
            }
          }
        })[0];
        this.picker.toDatePikerinstances = M.Datepicker.init(toDatePiker, {
          autoClose: true,
          minDate: new Date(Date.now()),
          onClose: function() {
            vm.current_event.to_flight_date_date = this.$el[0].value
            vm.picker.fromDatePikerinstances.options.maxDate = new Date(vm.current_event.to_flight_date_date);
          }
        })[0];
        this.picker.toTimePikerinstances = M.Timepicker.init(toTimePiker, {
          autoClose: true,
          onCloseEnd: function() {
            vm.current_event.to_flight_date_time = this.$el[0].value
          }
        })[0];
      }
    }
  },
  methods: {
    buildFlight: function(event) {
      if (event == this.current_event) return
      this.resetDatePikerMix();
      this.$parent.state_title = this.$t(`${this.cas}.form.title_edit`);
      this.$parent.show_form_content = true;
      event.from_flight_date_date = this.dateTimeHandlerMix(event.from_flight_date, 'date')
      event.from_flight_date_time = this.dateTimeHandlerMix(event.from_flight_date, 'time')
      event.to_flight_date_date   = this.dateTimeHandlerMix(event.to_flight_date, 'date')
      event.to_flight_date_time   = this.dateTimeHandlerMix(event.to_flight_date, 'time')
      this.current_event = event
      this.$scrollTo('#display_form');
    },
    update_log: function(events) {
      this.current_events = events;
    },
    new_flight_object: function() {
      if (!!this.current_event) return
      this.resetDatePikerMix();
      this.$parent.state_title = this.$t(`${this.cas}.form.title_new`);
      this.current_event = {
        id: null,
        from_flight_date_date: null,
        from_flight_date_time: null,
        to_flight_date_date: null,
        to_flight_date_time: null,
        notes: null,
        is_accepted: null,
        aircraft_id: null,
        users: []
      }
      this.$scrollTo('#form_card')
    },
    cancel_display: function(id = null) {
      var search_from_date, search_to_date
      if (!!this.$parent.$parent.$children[0].$children[0].search) {
        search_from_date = this.$parent.$parent.$children[0].$children[0].search.search_from_date
        search_to_date = this.$parent.$parent.$children[0].$children[0].search.search_to_date
      }
      if (!!this.$parent.$parent.$children[0].$children[0].timeline) {
        let current_times = this.$parent.$parent.$children[0].$children[0].timeline.getWindow()
        search_from_date = new Date(current_times.start)
        search_to_date = new Date(current_times.end)
      }

      bus.$emit('displayUpdatedLogs', id, search_from_date, search_to_date);
      this.$parent.state_title = this.$t(`${this.cas}.form.create_new_event`);
      this.$parent.show_form_content = true;
      this.current_event = null;
      this.its_create = true;
      bus.$emit('logsUpdate', []);
      bus.$emit('cleanListEventSelected')
    },
    cancel_display_no_ajax: function(id = null) {
      this.$parent.state_title = this.$t(`${this.cas}.form.create_new_event`);
      this.$parent.show_form_content = true;
      this.current_event = null;
      this.its_create = true;
      bus.$emit('logsUpdate', []);
      bus.$emit('cleanListEventSelected')
    },
    create_flight: function() {
      if (this.incall) return
      this.incall = true
      var [from_date, to_date] = this.validateTime();
      var ids = null;
      if (this.current_event) { ids = this.current_event.users.map(u => u.id) }
      this.$http.post('/en/events', {
        event: {
          from_flight_date: from_date,
          to_flight_date: to_date,
          aircraft_id: this.current_event.aircraft_id,
          user_ids: ids,
          notes: this.current_event.notes
        }
      }).then(response => {
        this.cancel_display();
        M.toast({html: 'Created Event.'});
        this.$scrollTo('#display_form');
        this.incall = false;
      }, response => {
        this.displayErrorMix(response)
        this.incall = false;
      });
    },
    update_flight: function(){
      if (this.incall) return
      this.incall = true
      var flight = this.current_event
      var [from_date, to_date] = this.validateTime()
      this.$http.put('/en/events/' + flight.id,{
        event: {
          from_flight_date: from_date,
          to_flight_date: to_date,
          aircraft_id: this.current_event.aircraft_id,
          user_ids: this.current_event.users.map(u => u.id),
          notes: this.current_event.notes
        }
      }).then(response => {
        this.cancel_display(response.body.id);
        bus.$emit('selectedEventList', flight.id)
        M.toast({html: 'Updated Event.'});
        bus.$emit('scrollDisplayForm')
        this.incall = false
      }, response => {
        this.displayErrorMix(response)
        this.incall = false
      });
    },
    destroy_flight: function() {
      if (this.incall) return
      if (confirm(this.$t(`${this.cas}.share.are_u_sure`))) {
        this.incall = true
        var flight = this.current_event
        this.$http.delete( '/en/events/' + flight.id,{
          event: { id: flight.id  }
        }).then(response => {
          M.toast({html: 'Destroy Event.'})
          var removeIndex = this.current_events.indexOf(this.current_event)
          this.current_events.splice(removeIndex, 1)
          this.cancel_display()
          this.incall = false
        }, response => {
          this.displayErrorMix(response)
          this.incall = false
          console.log('nope')
          console.log(response)
        })
      }
    },
    validateTime: function(){
      var from_date = '';
      var to_date = '';

      if (this.current_event.from_flight_date_date &&
          this.current_event.from_flight_date_time &&
          this.current_event.to_flight_date_date &&
          this.current_event.to_flight_date_time) {

        from_date = `${this.current_event.from_flight_date_date}, ${this.current_event.from_flight_date_time}`
        to_date = `${this.current_event.to_flight_date_date}, ${this.current_event.to_flight_date_time}`
        from_date = this.$moment(new Date(from_date))
        to_date = this.$moment(new Date(to_date))
        return [from_date, to_date]
      } else {
        return ['', '']
      }
    }
  },
  mixins: [applicationMixin, flightFormMixin]
}
</script>
